.container {
    width: 200px;
    height: 50px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.languageButton {
    width: 50px;
    height: 50px;
    background-color: var(--main);
    transform: skew(-8deg);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.languageText {
    text-transform: uppercase;
    color: var(--textOnMain);
    font-weight: 400;
    font-size: 18px;
    user-select: none;
}