.container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100dvw;
}

.formContainer {
    position: relative;
    display: flex;
    // overflow-y: scroll;
    background-color: var(--foreground);
    border-radius: 10px;
    border-width: 0px;
    border-style: solid;
    max-width: 100%;
}

.button {
    line-height: 18px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 400;
    font-size: 18px;
    background-color: var(--main);
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: capitalize;
    transform: skew(-8deg);
    color: var(--textOnMain);
}

.buttonBack {
    line-height: 18px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 400;
    font-size: 18px;
    background-color: var(--main);
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: capitalize;
    transform: skew(-8deg);
    color: var(--textOnMain);
}

.buttonBack:hover {
    opacity: 1 !important;
    background-color: var(--main);
}

.buttonContainer {
    width: calc(100% - 15px);
    display: flex;
    justify-content: space-between;
    background-color: var(--white);
    padding-top: 30px;
}

.pageNumber {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    user-select: none;
    font-weight: 500;
    font-size: 16px;
}

.form {
    width: calc(100% - 40px);
    padding-bottom: 30px;
    padding-left: 120px;
    padding-right: 120px;
}

@for $i from 1 through 6 {
    .formContainer[showpart="part-#{$i}"] > form > * > * > * > * > div[class*="part-#{$i}"] {
        position: relative;
        opacity: 1;
    }
    
    .formContainer[showpart="part-#{$i}"] > form > * > * > * > * > :not(div[class*="part-#{$i}"]) {
        position: absolute;
        top: -1000%;
        opacity: 0;
    }
}

.formContainer > form > * > * > * > div {
    padding-top: 0px !important;
    margin-bottom: 0px !important;
}

.formContainer > form > * > * > * > * > * > * > div[id*="title"] {
    display: none;
}

.buttonText {
    display: block;
}

.buttonArrows {
    display: none;
    color: var(--textOnMain);
}

@media only screen and (max-height: 896px) {
    .buttonContainer {
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 690px) {
    .formContainer {
        border-radius: 0;
        justify-content: center;
    }

    .form {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (max-width: 330px) {
    .buttonText {
        display: none;
    }

    .buttonArrows {
        display: block;
    }
}

@media only screen and (max-width: 270px) {
    .container {
        width: 270px;
        min-width: 270px;
    }
}