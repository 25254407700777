.container {
    position: relative;
    width: 100%;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contentContainer {
    position: relative;
    width: 60%;
    max-width: 640px;
    overflow: hidden;
    z-index: 1;
    grid-template-columns: auto;
    display: grid;
    grid-template-rows: min-content auto min-content;
}

.contentContainerSmall {
    height: min-content;
}

// breakpoints

// horizontal
@media only screen and (max-width: 1035px) {
    .contentContainer {
        width: 80%;
    }
}

@media only screen and (max-width: 777px) {
    .contentContainer {
        width: 90%;
    }
}

@media only screen and (max-width: 690px) {
    .contentContainer {
        width: 100%;
        max-width: unset;
    }
}

@media only screen and (max-width: 270px) {
    .container {
        min-width: 270px;
        width: 270px;
    }

    .contentContainer {
        min-width: 270px;
        width: 270px;
    }
}

// vertical
@media only screen and (max-height: 935px) {
    .container {
        height: 935px;
    }
}