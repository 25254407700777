.PrivatePickersYear-yearButton {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 5px !important;
    transform: skew(-8deg);
}

.PrivatePickersMonth-root {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 5px !important;
    transform: skew(-8deg);
}

.MuiPickersDay-root {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 5px !important;
    transform: skew(-8deg);
}

.PrivatePickersYear-yearButton.Mui-selected {
    background-color: var(--main) !important;
}

.PrivatePickersMonth-root.Mui-selected {
    background-color: var(--main) !important;
}

.MuiPickersDay-root.Mui-selected {
    background-color: var(--main) !important;
}

.MuiDialogActions-root > button {
    color: var(--main) !important;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}