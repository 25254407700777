.container {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;
    top: 0;
    width: 100%;
}

.titleContainer {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 50%;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
}

.logo {
    position: absolute;
    width: 300px;
    top: 5.9%;
    left: 50%;
    transform: translateX(-50%);
}

.title {
    position: relative;
    font-weight: 600;
    white-space: nowrap;
    user-select: none;
    font-size: 18px;
    text-transform: uppercase;
}

@media only screen and (max-width: 430px) {
    .logo {
        transform: scale(0.8) translateX(calc(-50% + -50% * 0.25));
        top: 2.9%;
    }
}

/* @media only screen and (max-height: 896px) {
    .container {
        height: 150px;
    }

    .logo {
        top: 15%;
        transform: scale(0.9) translateX(calc(-50% + -50% * 0.125))
    }
} */