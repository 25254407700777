.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    background-color: var(--foreground);
    padding: 30px;
    border-radius: 10px;
    border-width: 0px;
    border-style: solid;
}

.text {
    margin: 0;
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: 300;
    font-size: 18px;
}

.button {
    line-height: 18px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 400;
    font-size: 18px;
    background-color: var(--main);
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: none;
    transform: skew(-8deg);
    color: var(--textOnMain);
}

.smsButtonsContainer {
    display: flex;
    gap: 40px;
    height: 45px;
}

.recaptchaContainer {
    user-select: none;
    opacity: 0;
}

@media only screen and (max-width: 690px) {
    .container {
        border-radius: 0;
    }
}