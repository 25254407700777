:root {
    --background: #E6E6E6;
    --foreground: #F0F0F0;
    --main: #1BB366;
    --textOnMain: #F0F0F0;
    --textOnForeground: #0D0D0D;
    --inputBackground: #0D0D0D1A;
}

* {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    padding: 0;
    min-width: 100vw;
    min-height: 100dvh;
    background-color: var(--background);
}

a {
    color: var(--main);
}