.container {
    width: 100%;
    height: min-content;
}

div:has(> .container) {
    flex: 1 0 !important;
}

.recaptchaText {
    font-size: 12px;
    text-align: center;
    color: var(--textOnForeground);
    margin: 0;
    font-weight: 300;
}