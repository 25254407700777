.inputContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.inputLabel {
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    color: var(--textOnForeground);
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.radioLabel {
    position: relative;
    color: var(--textOnForeground);
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 30px;
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap;
}

.radioButton {
    width: 30px;
    height: 30px;
    background-color: var(--inputBackground);
    border-style: none;
}

.radioCheck {
    color: var(--textOnMain);
}