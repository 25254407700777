.inputContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.inputLabel {
    font-size: 14px;
    font-weight: 300;
    margin: 0;
}

.input > div > input {
    background-color: var(--inputBackground);
    border-width: 0;
    border-style: none;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 18px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 10px !important;
    padding-right: 20px;
}

.input > div > fieldset {
    border-width: 0;
    border-style: none;
}

.input > div > input {
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
}

.input > div[class*="Mui-focused"] > input {
    border-color: var(--main) !important;
}

.countryFlag {
    margin-right: 10px;
}

div:has(> .countryFlag) {
    padding-left: 0 !important;
}