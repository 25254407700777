.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    background-color: var(--foreground);
    padding: 30px;
    border-radius: 10px;
    border-width: 0px;
    border-style: solid;
}

.text {
    margin: 0;
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: 300;
    font-size: 16px;
}

.textBold {
    font-weight: 600;
}

.check {
    color: var(--main);
    height: 100px;
    width: 100px;
}

@media only screen and (max-width: 690px) {
    .container {
        border-radius: 0;
    }
}